import moment from "moment";
import angkaTerbilang from "@develoka/angka-terbilang-js";

const toThousandFormatapi = (number) => {
  return (number ? number : 0).toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });
};

const paperHeight = 140;

// Print invoice template is adjusted for printing on continuous form paper of size 24.3 x 14 cm on potrait
// before printing, adjust left margin to 0.21 inch and right margin to 0.5 inch from print dialog
const PrintInvoice = ({ dataInvArr, sipaAndFullnameData }) => {
  var elem = document.getElementById("print-area");

  // function to round each section to a multiple of paper height
  // window.addEventListener("load", function (event) {
  if (elem && dataInvArr) {
    var rect = elem.getBoundingClientRect();
    var tempHeight =
      Math.floor((rect.height * 0.2 + paperHeight) / paperHeight) * paperHeight;
    elem.style.height = tempHeight + "mm";
    elem.style.minHeight = tempHeight + "mm";
  }
  // });

  const newHeight = () => {
    if (elem) {
      var rect = elem.getBoundingClientRect();
      return (
        Math.floor((rect.height * 0.2 + paperHeight) / paperHeight) *
        paperHeight
      );
    }
  };

  return (
    <>
      <style>{`@media print {
        @page {
          size: 216mm 280mm potrait; 
          margin:0 !important;
          padding: 0 !important;
        }
        body {
          margin:0 !important; 
          padding:0 !important;
        }
        margin: 0 !important;
      }`}</style>
      {dataInvArr?.map((item, i) => (
        <div
          className={`min-h-[140mm] h-[${newHeight()}]`}
          id="print-area"
          key={i}
        >
          <div className="grid grid-cols-2 text-[9px]">
            <p className="w-full font-bold text-[12px]">{item.company_name}</p>
            <p>NPWP: {item.customer_npwp}</p>
            <p className="w-full">{item.company_address}</p>
            <p className="w-full">Telp: {item.customer_phone}</p>
          </div>

          <hr className="my-2" />

          <div className="grid grid-cols-2 text-[9px]">
            <div className="flex">
              <div className="w-[45%]">Nama Customer</div>
              <div className="w-[5%]">:</div>
              <div className="w-[50%]">{item.recepient}</div>
            </div>
            <div className="flex">
              <div className="w-[45%]">Jatuh Tempo</div>
              <div className="w-[5%]">:</div>
              <div className="w-[50%]">
                {moment(item.due_date).format("DD MMMM YYYY")}
              </div>
            </div>
            <div className="flex">
              <div className="w-[45%]">Nomor Faktur</div>
              <div className="w-[5%]">:</div>
              <div className="w-[50%]">{item.faktur_number}</div>
            </div>
            {/* <div className="flex">
              <div className="w-[45%]">Metode Pembayaran</div>
              <div className="w-[5%]">:</div>
              <div className="w-[50%]">
                {item.payment_method === "" ? "-" : item.payment_method}
              </div>
            </div> */}
            <div className="flex">
              <div className="w-[45%]">Kode Sales</div>
              <div className="w-[5%]">:</div>
              <div className="w-[50%]">
                {item.sales_code?.length <= 1 ? "-" : item.sales_code}
              </div>
            </div>
          </div>

          <table className="w-full my-2 text-[9px]">
            {/* <thead className="text-center text-[9px]"> */}

            {/* </thead> */}
            <tbody>
              <tr>
                <td className="p-1 border-2">No</td>
                <td className="p-1 border-2">Nama Produk</td>
                {/* <td className="p-1 border-2">ID</td> */}
                <td className="p-1 border-2">Qty</td>
                {/* <td className="p-1 border-2">Kemasan</td> */}
                <td className="p-1 border-2">Batch</td>
                <td className="p-1 border-2">Exp</td>
                <td className="p-1 border-2">Harga</td>
                <td className="p-1 border-2">Disc</td>
                <td className="p-1 border-2">Total</td>
                <td className="p-1 border-2">Total + Disc</td>
                <td className="p-1 border-2">Promo</td>
              </tr>
              {item.product?.map((prod, i) => {
                return (
                  <tr className="text-center">
                    <td className="p-1 border-2">{i + 1}</td>
                    <td className="p-1 border-2">
                      {prod.product_name + " (ID: " + prod.product_id + ")"}
                    </td>
                    {/* <td className="p-1 border-2">{prod.product_id}</td> */}
                    <td className="p-1 border-2">
                      {prod.quantity} {prod.packaging}
                    </td>
                    <td className="p-1 border-2">{prod.batch_no}</td>
                    <td className="p-1 border-2">{prod.expired_date}</td>
                    <td className="p-1 border-2">
                      {toThousandFormatapi(prod.price)}
                    </td>
                    <td className="p-1 border-2">
                      {prod.discount + item.customer_discount}%
                    </td>
                    <td className="p-1 border-2">
                      {toThousandFormatapi(prod.sub_total)}
                    </td>
                    <td className="p-1 border-2">
                      {toThousandFormatapi(prod.total)}
                    </td>
                    <td className="p-1 border-2">
                      {prod.promo && prod.promo === "" ? "-" : prod.promo}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="keep-together">
            <div className="flex text-[9px] items-start">
              <div className="basis-2/3">
                <div className="flex gap-1 items-center">
                  <p className="font-bold mt-2">Terbilang:</p>
                  <p className="bg-gray-200 p-2 border-2 w-full mx-4">
                    {angkaTerbilang(item.jumlah_bayar).toUpperCase()} RUPIAH
                  </p>
                </div>
                <p className="font-bold mt-2">Catatan:</p>
                <p>{item.invoice_note}</p>
              </div>
              <div className="basis-1/3 font-bold text-[9px]">
                <div className="flex">
                  <div className="w-[45%]">Jumlah</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.jumlah)}
                  </div>
                </div>
                {/* <div className="flex">
                  <div className="w-[45%]">Diskon 1</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.discount_1)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">Diskon 2</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.discount_2)}
                  </div>
                </div> */}
                <div className="flex">
                  <div className="w-[45%]">Diskon</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.discount)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">Subtotal</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.subtotal)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">DPP</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.dpp ? item.dpp : 0)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">PPN</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">{toThousandFormatapi(item.ppn)}</div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">Voucher</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.voucher)}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[45%]">Jumlah Tagihan</div>
                  <div className="w-[5%]">:</div>
                  <div className="w-[50%]">
                    {toThousandFormatapi(item.jumlah_bayar - item.total_amount)}
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-2" />

            <div className="keep-together flex items-start text-[9px] gap-[50%]">
              <div className="w-full text-center">
                <p className="font-bold mb-16">{item.company_name}</p>
                <p className="font-bold">
                  {sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.fullname
                    : "-"}{" "}
                  <br /> SIPA:
                  {sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.sipa_id
                    : "-"}
                </p>
              </div>
              <div className="w-full text-center">
                <p className="font-bold mb-16">
                  {moment(item.invoice_date).format("DD MMMM YYYY")}
                </p>
                <p className="font-bold">{item.customer_name}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PrintInvoice;
