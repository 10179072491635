import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  editBillingManual,
  getBillingManualDetailNew,
  updateFakturBilling,
} from "../../../services/billingAPI";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { LuFileCheck } from "react-icons/lu";
import { FaRegEdit } from "react-icons/fa";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import ErrorPopup from "../../common/particles.jsx/ErrorPopup";
import { useFormik } from "formik";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { PPN_OPTION_INT } from "../../constants/constant";
import { BsTrashFill } from "react-icons/bs";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const EditBillingManual = () => {
  const { id } = useParams();
  const { billId } = useParams();
  const navigate = useNavigate();

  const [faktur, setFaktur] = useState("");
  const [isEditFaktur, setIsEditFaktur] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getGrandTotal = () => {
    var total = 0;
    formik.values.product.map(
      (item) =>
        (total += item.batch.reduce((acc, num) => acc + num.total_amount, 0))
    );

    return (
      total - (data && data.po_detail?.voucher ? data.po_detail.voucher : 0)
    );
  };

  const handleDpp = (ppnVal) => {
    return ppnVal ? (ppnVal === 11 ? 11 : (11 / 12) * 12) : 0;
  };

  const findDpp = (ppn, hna, discount) => {
    const price = hna * ((100 - discount) / 100);
    return ppn ? (ppn === 11 ? price : ppn === 12 ? price * (11 / 12) : 0) : 0;
  };

  const handleChangeValue = (i, index, type, val) => {
    var tempProduct = [...formik.values.product];
    var hna =
      type === "hna" ? val : tempProduct[i].batch[index].pharmacy_net_price;
    var discount =
      type === "discount" ? val : tempProduct[i].batch[index].discount;
    var ppn = handleDpp(type === "ppn" ? val : tempProduct[i].batch[index].ppn);

    const discPrice = hna * ((100 - discount) / 100);
    const finalPrice = discPrice * ((100 + ppn) / 100);

    // setting values
    tempProduct[i].batch[index][type] = val;
    tempProduct[i].batch[index].net_price = finalPrice;
    tempProduct[i].batch[index].total_amount =
      finalPrice * tempProduct[i].batch[index].quantity;
    if (type === "po_stock") tempProduct[i].po_stock = val;

    formik.setFieldValue("product", tempProduct);
  };

  const handleAddBatch = (i, price) => {
    var tempProduct = [...formik.values.product];
    tempProduct[i].batch.push({
      batch_id: 0,
      batch_number: "",
      expired_date: moment().format("YYYY-MM-DD"),
      pharmacy_net_price: price ? price.hna_po : 0,
      type_packaging: tempProduct[i].batch[0].type_packaging,
      quantity: 0,
      discount: price ? price.discount : 0,
      ppn: price ? price.ppn : 0,
      dpp: price ? price.dpp : 0,
      net_price: price ? price.net_price : 0,
      total_amount: 0,
      is_delete: false,
    });

    formik.setFieldValue("product", tempProduct);
  };

  const handleSave = () => {
    mutateEditBilling(formik.values);
  };

  const {
    data,
    isFetching: isFetchingData,
    refetch,
  } = useQuery({
    queryKey: ["detail-bill", id, billId],
    queryFn: () => getBillingManualDetailNew(id, billId),
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateSetFaktur, isLoading } = useMutation({
    mutationFn: updateFakturBilling,
    onSuccess: () => {
      setFaktur("");
      setIsEditFaktur(false);
      refetch();
    },
    onError: (err) => {
      setFaktur("");
      setErrorMessage(err.message.id ? err.message.id : err.message);
    },
  });

  const { mutate: mutateEditBilling, isLoading: isLoadingEdit } = useMutation({
    mutationFn: editBillingManual,
    onSuccess: (data) => {
      if (data && data.message) navigate("/manual-billing");
      else {
        setErrorMessage("Terjadi kesalahan saat menyimpan billing");
      }
    },
    onError: (err) => {
      setErrorMessage(err.message.id ? err.message.id : err.message);
    },
  });

  const formik = useFormik({
    initialValues: {
      billing_id: 0,
      product: [],
    },
  });

  useEffect(() => {
    if (data) {
      setFaktur(data.po_detail.faktur_number);
      formik.setValues({
        billing_id: data.billing_id,
        product: data.product_detail.map((item) => ({
          ...item,
          batch: [
            ...item.batch.map((batchItem) => ({
              ...batchItem,
              expired_date: moment(batchItem.expired_date).format("YYYY-MM-DD"),
              is_delete: false,
            })),
          ],
        })),
      });
    }
  }, [data]);

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isLoading || isLoadingEdit} />
      <div className="mb-4 flex gap-4 w-full ">
        <div className="w-full">
          <p className="font-bold">Tanggal PO</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.po_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor PO</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.po_number}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Distributor</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.distributor_name}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Tanggal Invoice</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.invoice_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor Invoice</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.invoice_number}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor Faktur Pajak</p>
          {data && !isFetchingData ? (
            <>
              {isEditFaktur ? (
                <div className="flex gap-2 w-full items-center">
                  <TextField
                    fullWidth
                    value={faktur}
                    onChange={(e) => setFaktur(e.target.value)}
                  />
                  <MdCancel
                    className="text-red-500 hover:text-red-700 cursor-pointer text-[18px]"
                    onClick={() => {
                      setFaktur("");
                      setIsEditFaktur(false);
                    }}
                  />
                  <LuFileCheck
                    className="text-blue-500 hover:text-blue-700 cursor-pointer text-[18px]"
                    onClick={() =>
                      mutateSetFaktur({
                        po_id: Number(id),
                        billing_id: Number(data.billing_id),
                        faktur_number: faktur,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="flex gap-2 w-full items-center justify-between">
                  <p>
                    {data.po_detail.faktur_number === ""
                      ? "-"
                      : data.po_detail.faktur_number}
                  </p>
                  <FaRegEdit
                    className="text-[18px] text-blue-500 hover:text-blue-700 cursor-pointer "
                    onClick={() => setIsEditFaktur(true)}
                  />
                </div>
              )}
            </>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
      </div>
      <div className="mb-4 flex gap-4 w-full">
        <div className="w-full">
          <p className="font-bold">Total Tagihan</p>
          {data && !isFetchingData ? (
            <p>{toIDR(data.po_detail.total_amount)}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Total Dibayar</p>
          {data && !isFetchingData ? (
            <p>{toIDR(data.po_detail.total_payment)}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Status</p>
          {data && !isFetchingData ? (
            <p>
              {data.po_detail.status === 1
                ? "Belum dibayar"
                : data.po_detail.status === 2
                ? "Overdue"
                : data.po_detail.status === 3
                ? "Lunas"
                : data.po_detail.status === 4
                ? "Draft"
                : data.po_detail.status === 5
                ? "Belum Lunas"
                : "-"}
            </p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Jatuh Tempo</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.po_due_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full" />
        <div className="w-full" />
      </div>

      <div className="flex w-full mb-4">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap min-w-[150px] sticky left-0 z-1 bg-blue-500">
                  Produk
                </th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  Kuantitas PO
                </th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  Batch
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Expired
                </th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  Kuantitas Diterima
                </th>
                <th className="p-2 text-center whitespace-nowrap">Kemasan</th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  HNA Satuan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Diskon (%)
                </th>
                <th className="p-2 text-center whitespace-nowrap">PPN (%)</th>
                <th className="p-2 text-center whitespace-nowrap">DPP</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Harga Akhir
                </th>
                <th className="p-2 text-center whitespace-nowrap">Sub Total</th>
              </thead>
              <tbody className="border">
                {data && !isFetchingData ? (
                  formik.values.product.map((item, i) =>
                    item.batch.map((batchItem, index) =>
                      batchItem.is_delete ? null : (
                        <tr
                          className={`${
                            i === 0 && index === 0 ? "" : "border-t"
                          } `}
                        >
                          <td className="px-2 py-4 text-center align-top">
                            {index === 0 ? i + 1 : ""}
                          </td>
                          <td className="px-2 py-4 text-center text-nowrap whitespace-nowrap align-top sticky left-0 z-10 bg-white">
                            {index === 0 ? item.product_name : ""}
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">
                              {index === 0 ? item.stock_po : ""}
                            </p>
                          </td>
                          <td className="px-2 py-4 text-right align-top">
                            <div className="flex gap-2 items-center">
                              <div className="w-full">
                                <TextField
                                  fullWidth
                                  value={batchItem.batch_number}
                                  onChange={(e) =>
                                    handleChangeValue(
                                      i,
                                      index,
                                      "batch_number",
                                      e.target.value
                                    )
                                  }
                                />
                                {index === item.batch.length - 1 ? (
                                  <p
                                    className={`mt-2 ${
                                      item.batch.reduce(
                                        (acc, num) => acc + num.quantity,
                                        0
                                      ) < item.stock_po
                                        ? "text-blue-500 hover:underline cursor-pointer"
                                        : "text-gray-300 cursor-default"
                                    }`}
                                    onClick={() => {
                                      if (
                                        item.batch.reduce(
                                          (acc, num) => acc + num.quantity,
                                          0
                                        ) < item.stock_po
                                      )
                                        handleAddBatch(i, {
                                          hna_po: item.hna_po,
                                          discount: item.discount_po,
                                          ppn: item.ppn_value_po,
                                          dpp: item.dpp_po,
                                          net_price: item.netprice_po,
                                        });
                                    }}
                                  >
                                    + Tambah Batch
                                  </p>
                                ) : null}
                              </div>
                              {item.batch.length === 1 ? null : (
                                <MdCancel
                                  className="text-[24px] text-red-500 cursor-pointer hover:text-gray-300"
                                  onClick={() => {
                                    var tempProduct = [
                                      ...formik.values.product,
                                    ];

                                    tempProduct[i].batch =
                                      tempProduct[i].batch[i].billing_id === 0
                                        ? [
                                            ...tempProduct[i].batch.slice(
                                              0,
                                              index
                                            ),
                                            ...tempProduct[i].batch.slice(
                                              index + 1
                                            ),
                                          ]
                                        : [
                                            ...tempProduct[i].batch.slice(
                                              0,
                                              index
                                            ),
                                            {
                                              ...tempProduct[i].batch[i],
                                              is_delete: true,
                                            },
                                            ...tempProduct[i].batch.slice(
                                              index + 1
                                            ),
                                          ];
                                    formik.setFieldValue(
                                      "product",
                                      tempProduct
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <DesktopDatePicker
                              views={["year", "month", "day"]}
                              value={batchItem.expired_date}
                              onChange={(newExpiredDate) =>
                                handleChangeValue(
                                  i,
                                  index,
                                  "expired_date",
                                  newExpiredDate
                                    ? moment(newExpiredDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                )
                              }
                              sx={{ width: "100%" }}
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                            />
                          </td>

                          <td className="px-2 py-4 text-center align-top">
                            <div>
                              <TextField
                                fullWidth
                                value={batchItem.quantity}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value)))
                                    handleChangeValue(
                                      i,
                                      index,
                                      "quantity",
                                      Number(e.target.value)
                                    );
                                }}
                              />
                              {/* {item.po_stock <
                              item.batch.reduce(
                                (acc, num) => acc + num.quantity_received,
                                0
                              ) && index === item.batch.length - 1 ? (
                              <p className="text-red-500 text-[10px]">
                                Stok melebihi batas maksimal
                              </p>
                            ) : null} */}
                            </div>
                          </td>

                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">{batchItem.type_packaging}</p>
                          </td>
                          <td className="px-2 py-4 text-left align-top">
                            <div>
                              <TextField
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      Rp
                                    </InputAdornment>
                                  ),
                                }}
                                value={batchItem.pharmacy_net_price}
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value))) {
                                    handleChangeValue(
                                      i,
                                      index,
                                      "pharmacy_net_price",
                                      Number(e.target.value)
                                    );
                                    handleChangeValue(
                                      i,
                                      index,
                                      "dpp",
                                      findDpp(
                                        batchItem.ppn,
                                        Number(e.target.value),
                                        batchItem.discount
                                      )
                                    );
                                  }
                                }}
                              />
                              {batchItem.pharmacy_net_price >
                              batchItem.hna_compare ? (
                                <p className="text-red-500 text-[10px]">
                                  Anda menaikan harga akhir, ada potensi
                                  kerugian
                                </p>
                              ) : null}
                            </div>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <TextField
                              fullWidth
                              value={
                                batchItem.discount
                                  ? batchItem.discount.toString()
                                  : 0
                              }
                              type="number"
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              onChange={(e) => {
                                if (
                                  !isNaN(Number(e.target.value)) &&
                                  Number(e.target.value) < 100
                                ) {
                                  handleChangeValue(
                                    i,
                                    index,
                                    "discount",
                                    Number(e.target.value)
                                  );
                                  handleChangeValue(
                                    i,
                                    index,
                                    "dpp",
                                    findDpp(
                                      batchItem.ppn,
                                      batchItem.pharmacy_net_price,
                                      batchItem.discount
                                    )
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <Autocomplete
                              disablePortal
                              fullWidth
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              options={PPN_OPTION_INT}
                              value={
                                batchItem.ppn !== 0
                                  ? PPN_OPTION_INT.find(
                                      (ppn) => ppn.value === batchItem.ppn
                                    )
                                  : null
                              }
                              sx={{ backgroundColor: "white" }}
                              onChange={(_, inputValue) => {
                                handleChangeValue(
                                  i,
                                  index,
                                  "ppn",
                                  inputValue ? inputValue.value : 0
                                );
                                handleChangeValue(
                                  i,
                                  index,
                                  "dpp",
                                  findDpp(
                                    inputValue ? inputValue.value : 0,
                                    batchItem.pharmacy_net_price,
                                    batchItem.discount
                                  )
                                );
                              }}
                              getOptionLabel={(option) =>
                                option ? option.name : "Pilih PPN"
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="Pilih PPN"
                                />
                              )}
                            />
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2"> {toIDR(batchItem.dpp)}</p>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">
                              {" "}
                              {toIDR(batchItem.net_price)}
                            </p>
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <p className="mt-2">
                              {toIDR(batchItem.total_amount)}
                            </p>
                          </td>
                        </tr>
                      )
                    )
                  )
                ) : (
                  <tr>
                    <td className="border p-2 text-center" colSpan={13}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="w-full flex items-end justify-end mb-8 pb-[60px]">
        <div className="flex gap-2 items-center mb-2">
          <p className="w-1/3">Voucher</p>
          <p>:</p>
          <TextField
            fullWidth
            disabled
            value={toIDR(
              data && data.po_detail?.voucher ? data.po_detail.voucher : 0
            )}
          />
        </div>
      </div>

      <div
        className={`bg-blue-500 fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] z-50`}
      >
        <div className="flex items-center justify-between items-center w-full">
          <div className="text-white">
            <p className="text-[14px]">Total</p>
            <p className="text-[18px] font-bold">
              {getGrandTotal().toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </p>
          </div>
          <button
            type="button"
            disabled={
              !formik.values.product.every((item) =>
                item.batch.every(
                  (batchItem) =>
                    batchItem.is_delete ||
                    (batchItem.quantity > 0 && batchItem.batch_number !== "")
                )
              ) || isLoadingEdit
            }
            onClick={handleSave}
            className="rounded-xl text-[14px] disabled:bg-[#687B8E] text-white py-[10px] px-[42px] bg-[#FF8D40] hover:bg-orange-500"
          >
            {isLoadingEdit ? <CircularProgress size={20} /> : "Simpan"}
          </button>
        </div>
      </div>

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default EditBillingManual;
