import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Popover,
  Popper,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdPerson } from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Papa from "papaparse";
import { getListBillingNew } from "../../../services/billingAPI";
import useDebounce from "../../hooks/useDebounce";
import PagePagination from "../../common/particles.jsx/PagePagination";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";
import ExportBillingModal from "../billingManualCW/ExportBillingModal";
import ImportBillingModal from "../billingManualCW/ImportBillingModal";
import { BiPrinter } from "react-icons/bi";
import {
  exportCsvBilling,
  getDistributorListV2All,
} from "../../../services/purchaseOrderApi";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import ModalPaymentBillingList from "./modal/ModalPaymentBillingList";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const STATUS = [
  // {
  //   id: 0,
  //   value: "Semua",
  // },
  {
    id: 1,
    value: "Belum Dibayar",
  },
  {
    id: 5,
    value: "Belum Lunas",
  },
  {
    id: 2,
    value: "Telat Bayar",
  },
  {
    id: 3,
    value: "Lunas",
  },
  {
    id: 4,
    value: "Draft",
  },
];

const INITIAL_REQ = {
  offset: 1,
  limit: 50,
  status: [1, 2, 3, 4, 5],
  search: "",
  start_date: "",
  end_date: "",
  period: 0,
  distributor_id: [],
};

const BillingManualList = () => {
  const navigate = useNavigate();
  const { pageSetting } = useParams();

  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElDistributor, setAnchorElDistributor] = useState(null);
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [moreProductArr, setMoreProductArr] = useState([]);
  const [anchorElMoreProduct, setAnchorElMoreProduct] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [billingIdArr, setBillingIdArr] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [searchDistributor, setSearchDistributor] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [periodDummy, setPeriodDummy] = useState(0);

  const debounce = useDebounce(reqBody.search, 500);
  const debounceDistributor = useDebounce(searchDistributor, 500);

  const getTotalBilling = () => {
    var total = 0;
    billingIdArr.map((id) => {
      const bill = dataList.data.find((item) => item.billing_id === id);
      if (bill && bill.status !== 3 && bill.status !== 4)
        total = total + bill.total_bill - bill.total_amount;
    });
    return total;
  };

  const getTotalPaid = () => {
    var total = 0;
    billingIdArr.map((id) => {
      const bill = dataList.data.find((item) => item.billing_id === id);
      if (bill && bill.status === 3) total = total + bill.total_bill;
    });
    return total;
  };

  const isAllPaid = () => {
    var temp = [];
    billingIdArr.map((id) => {
      temp.push(dataList.data.find((item) => item.billing_id === id));
    });

    return temp.every((item) => item.status === 3);
  };

  const handleAddBillingId = (id) => {
    const index = billingIdArr.findIndex((item) => item === id);
    if (index < 0) setBillingIdArr([...billingIdArr, id]);
    else
      setBillingIdArr([
        ...billingIdArr.slice(0, index),
        ...billingIdArr.slice(index + 1),
      ]);
  };

  const handleSelectAll = () => {
    const isEmpty = billingIdArr.length === 0;
    setBillingIdArr(
      isEmpty
        ? dataList.data
            .filter((item) => item.status !== 4)
            .map((item) => item.billing_id)
        : []
    );
  };

  const handleJsonToCsvExisting = (csvExisting) => {
    const csv = Papa.unparse(csvExisting, {
      delimiter: ";",
    });

    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "OLIN_Billing_" + moment().format("DD_MM_YYYY_hh:mm") + ".csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const navigateTo = (
    newOffset,
    newLimit,
    newStart,
    newEnd,
    newStatus,
    newPeriod,
    newDistributor
  ) => {
    navigate(
      `/manual-billing/offset=${newOffset}&limit=${newLimit}&period=${newPeriod}&start_date=${newStart}&end_date=${newEnd}&status=${encodeURIComponent(
        newStatus
      )}&distributor_id=${encodeURIComponent(newDistributor)}`
    );
  };

  const { mutate: mutateCsvBilling, isLoading: isCsvBillingLoading } =
    useMutation({
      mutationFn: exportCsvBilling,
      onSuccess: (data) => {
        handleJsonToCsvExisting(
          data.map((data) => ({
            ...data,
            price: "Rp. " + data.price,
            total_price: "Rp. " + data.total_price,
            due_date: moment(data.due_date).format("DD-MMMM-YYYY"),
            po_date: moment(data.po_date).format("DD-MMMM-YYYY"),
            expired_date: moment(data.expired_date).format("DD-MMMM-YYYY"),
            status: STATUS.find((item) => item.id === data.status)?.value,
          }))
        );
      },
    });

  const { data: dataDistributor, isFetching: isFetchingDistributor } = useQuery(
    {
      queryKey: ["distributor-list", debounceDistributor],
      queryFn: () => getDistributorListV2All(debounceDistributor),
      refetchOnWindowFocus: false,
    }
  );

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getListBillingNew,
  });

  useEffect(() => {
    if (pageSetting) {
      var limitSetting,
        offsetSetting,
        period,
        startDate,
        endDate,
        statusSetting,
        distributorId;

      pageSetting.split("&").map((item) => {
        var val = item.split("=");
        if (val[0] === "limit") limitSetting = val[1];
        if (val[0] === "offset") offsetSetting = val[1];
        if (val[0] === "period") period = val[1];
        if (val[0] === "start_date") startDate = val[1];
        if (val[0] === "end_date") endDate = val[1];
        if (val[0] === "status")
          statusSetting = decodeURIComponent(val[1])
            .split(",")
            .map((item) => {
              return Number(item);
            });
        if (val[0] === "distributor_id")
          distributorId = decodeURIComponent(val[1])
            .split(",")
            .map((item) => {
              return Number(item);
            });
      });

      setReqBody((prevVal) => ({
        ...prevVal,
        limit: limitSetting ? Number(limitSetting) : 50,
        offset: offsetSetting ? Number(offsetSetting) : 1,
        period: period ? Number(period) : 0,
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        status: statusSetting && statusSetting !== "" ? statusSetting : [],
        distributor_id:
          distributorId && distributorId !== "" ? distributorId : [],
      }));
      setPeriodDummy(period ? (Number(period) === 9 ? 10 : Number(period)) : 0);
    }
  }, [pageSetting]);

  useEffect(() => {
    mutateList({ ...reqBody, search: debounce });
  }, [reqBody, debounce]);

  useEffect(() => {
    setIsSelectAll(
      dataList && dataList.data && !isLoading && billingIdArr.length > 0
        ? dataList.data.filter((item) => item.status !== 4).length ===
            billingIdArr.length
        : false
    );
  }, [billingIdArr]);

  useEffect(() => {
    if (
      dataDistributor &&
      !isFetchingDistributor &&
      dataDistributor.data &&
      reqBody.distributor_id.length === 0
    ) {
      setReqBody((prevVal) => ({
        ...prevVal,
        distributor_id: dataDistributor.data
          .filter((item) => item.is_used)
          .map((item) => item.id),
      }));
    }
  }, [dataDistributor]);

  return (
    <>
      <div className="text-[12px] px-3 pt-3 pb-[70px]">
        <LoadingBackdrop isLoading={isCsvBillingLoading} />
        <div className="flex items-center gap-2 mb-2">
          <TextField
            placeholder="Cari data"
            value={reqBody.search}
            onChange={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, search: e.target.value }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <div
            className={`border rounded-md flex items-center justify-between gap-4 w-fit py-2.5 px-2 ${
              dataDistributor && !isFetchingDistributor && dataDistributor.data
                ? "text-gray-500 cursor-pointer hover:bg-gray-100"
                : "bg-gray-300 text-white cursor-default"
            }`}
            onClick={(e) => {
              if (
                dataDistributor &&
                !isFetchingDistributor &&
                dataDistributor.data
              ) {
                setAnchorElDistributor(e.currentTarget);

                if (reqBody.distributor_id.length === 0)
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    distributor_id: dataDistributor.data
                      .filter((item) => item.is_used)
                      .map((item) => item.id),
                  }));
              }
            }}
          >
            <div className="flex items-center gap-2">
              <MdPerson className="text-[18px]" /> Distributor
            </div>
            <FaChevronDown />
          </div>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-2.5 px-2 text-gray-500 cursor-pointer hover:bg-gray-100"
            onClick={(e) => setAnchorElStatus(e.currentTarget)}
          >
            <div className="flex items-center gap-2">
              <CiFilter className="text-[18px]" /> Status
            </div>
            <FaChevronDown />
          </div>
          <Select
            value={periodDummy}
            onChange={(e) => {
              setReqBody((prevValue) => ({
                ...prevValue,
                period: e.target.value === 10 ? 9 : e.target.value,
                start_date: "",
                end_date: "",
              }));
              if (e.target.value === 9) {
                setOpenCalendar(true);
                return;
              } else if (e.target.value !== 10) {
                navigateTo(
                  reqBody.offset,
                  reqBody.limit,
                  "",
                  "",
                  reqBody.status,
                  e.target.value,
                  reqBody.distributor_id
                );
                setOpenCalendar(false);
                setStartDate(null);
                setEndDate(null);
              }
              setPeriodDummy(e.target.value);
            }}
          >
            <MenuItem value={0}>Semua Periode</MenuItem>
            <MenuItem value={1}>Hari ini</MenuItem>
            <MenuItem value={2}>Kemarin</MenuItem>
            <MenuItem value={4}>Minggu Berjalan</MenuItem>
            <MenuItem value={3}>Minggu Lalu</MenuItem>
            <MenuItem value={6}>Bulan Berjalan</MenuItem>
            <MenuItem value={5}>Bulan Lalu</MenuItem>
            <MenuItem value={8}>Tahun Berjalan</MenuItem>
            <MenuItem value={7}>Tahun Lalu</MenuItem>
            <MenuItem value={9}>Pilih Tanggal</MenuItem>
            <MenuItem value={10} disabled sx={{ display: "none" }}>
              {moment(reqBody.start_date).format("DD/MM/YYYY") +
                " - " +
                moment(reqBody.end_date).format("DD/MM/YYYY")}
            </MenuItem>
          </Select>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-1.5 px-2 border-blue-500 bg-[#E5EBF1] text-blue-500 cursor-pointer hover:bg-gray-100"
            onClick={() => setOpenImport(true)}
          >
            <p className="hidden lg:block">Import</p>
            <div className="bg-blue-500 text-white w-[24px] h-[24px] p-1 rounded-md ">
              <TbFileExport className="w-full h-full" />
            </div>
          </div>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-1.5 px-2 border-[#15803D] bg-[#AFECC6] text-[#15803D] cursor-pointer hover:bg-gray-100"
            onClick={() => setOpenExport(true)}
          >
            <p className="hidden lg:block">Export</p>
            <div className="bg-[#15803D] text-white w-[24px] h-[24px] p-1 rounded-md ">
              <TbFileExport className="w-full h-full" />
            </div>
          </div>
          <button
            className="disabled:bg-gray-300 disabled:text-white text-blue-500 border-2 border-blue-500 rounded-md hover:bg-slate-100 w-fit flex items-center justify-center p-2"
            onClick={() => {
              mutateCsvBilling({
                warehouse: false,
                billing_id: billingIdArr,
              });
            }}
            disabled={isCsvBillingLoading || billingIdArr.length <= 0}
          >
            <BiPrinter className="text-[18px]" />
          </button>
          <button
            type="button"
            className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
            onClick={() => navigate(`/manual-billing/po-list`)}
          >
            Buat Billing
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>

        <div className="flex w-full mb-8">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2 text-center">
                    <Checkbox
                      disabled={isLoading || !dataList}
                      onChange={handleSelectAll}
                      checked={isSelectAll}
                    />
                  </th>
                  <th className="p-2 text-center">No</th>
                  <th className="p-2 text-center whitespace-nowrap">Tanggal</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    No Invoice
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    No Faktur Pajak
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Distributor
                  </th>
                  <th className="p-2 text-left whitespace-nowrap">Produk</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Total Tagihan
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Total Dibayar
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">Status</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Jatuh Tempo
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">Action</th>
                </thead>
                <tbody>
                  {dataList && !isLoading && dataList.data ? (
                    dataList.data.map((item, i) => (
                      <tr className="border" key={i}>
                        <td className="p-2 text-center">
                          <Checkbox
                            disabled={item.status === 4}
                            checked={billingIdArr.includes(item.billing_id)}
                            onChange={() => handleAddBillingId(item.billing_id)}
                          />
                        </td>
                        <td className="p-2 text-center">
                          {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                        </td>
                        <td className="p-2 text-center">
                          {moment(item.created_at).format("DD/MM/YYYY ")}
                        </td>
                        <td className="p-2 text-center">
                          {item.invoice_number}
                        </td>
                        <td className="p-2 text-center">
                          {item.faktur_number}
                        </td>
                        <td className="p-2 text-center">{item.distributor}</td>
                        <td className="p-2 text-left">
                          <div className="w-full flex justify-between gap-4 items-center">
                            <p>
                              {item.product_name ? item.product_name[0] : "-"}
                            </p>
                            {item.product_name &&
                            item.product_name.length > 1 ? (
                              <p
                                className="text-blue-500 cursor-pointer"
                                onMouseEnter={(e) => {
                                  setMoreProductArr(item.product_name.slice(1));
                                  setAnchorElMoreProduct(e.currentTarget);
                                }}
                                onMouseLeave={() =>
                                  setAnchorElMoreProduct(null)
                                }
                              >
                                +{item.product_name.length - 1} Lainnya
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.total_bill)}
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.total_amount)}
                        </td>
                        <td className="p-2 text-center">
                          <p
                            className={`w-full rounded-full py-1 px-3 whitespace-nowrap text-nowrap font-semibold ${
                              item.status === 1
                                ? "bg-[#F1EBE5] text-red-500"
                                : item.status === 2
                                ? "bg-[#eee5f1] text-purple-500"
                                : item.status === 3
                                ? "bg-[#E5F1E6] text-green-500"
                                : item.status === 5
                                ? "bg-[#F1EBE5] text-yellow-500"
                                : "bg-[#E8E8E8] text-black"
                            }`}
                          >
                            {item.status === 1
                              ? "Belum dibayar"
                              : item.status === 2
                              ? "Overdue"
                              : item.status === 3
                              ? "Lunas"
                              : item.status === 4
                              ? "Draft"
                              : item.status === 5
                              ? "Belum Lunas"
                              : "-"}
                          </p>
                        </td>
                        <td className="p-2 text-center">
                          {moment(item.overdue_date).format("DD/MM/YYYY")}
                        </td>
                        <td className="p-2 text-center">
                          <div className="w-full flex gap-2 items-center">
                            <a
                              className="bg-gray-100 border-gray-300 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                              href={
                                item.status === 4
                                  ? `/manual-billing/edit-draft/${item.po_id}/${item.is_partial}`
                                  : `/manual-billing/detail/${item.po_id}/${item.billing_id}`
                              }
                            >
                              Detail
                            </a>
                            <a
                              className={`bg-gray-100 border-gray-300 border-2 rounded-md w-full p-1 
                                ${
                                  item.status === 1 || item.status === 4
                                    ? "text-gray-500 hover:bg-gray-300 cursor-pointer"
                                    : "text-white bg-gray-300 cursor-default"
                                }`}
                              href={
                                item.status === 1
                                  ? `/manual-billing/edit-billing/${item.po_id}/${item.billing_id}`
                                  : item.status === 4
                                  ? `/manual-billing/edit-draft/${item.po_id}/${item.is_partial}`
                                  : null
                              }
                            >
                              Edit
                            </a>
                            <button
                              className="bg-blue-100 text-blue-500 border-2 rounded-md w-full hover:bg-blue-300 p-1 disabled:bg-gray-300 disabled:text-white"
                              type="button"
                              disabled={item.status === 4}
                              onClick={() => {
                                mutateCsvBilling({
                                  warehouse: false,
                                  billing_id: [item.billing_id],
                                });
                              }}
                            >
                              Print
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-2 text-center border" colSpan={12}>
                        {isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada data"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {dataList && dataList.count > 0 && dataList.data !== null ? (
          <>
            <div className="mb-4">
              <PagePagination
                offset={reqBody.offset}
                setOffsetMemory={(limit, offset) => {
                  setBillingIdArr([]);
                  navigateTo(
                    offset,
                    limit,
                    reqBody.start_date,
                    reqBody.end_date,
                    reqBody.status,
                    reqBody.period,
                    reqBody.distributor_id
                  );
                }}
                limit={reqBody.limit}
                setLimit={(e) => {
                  setBillingIdArr([]);
                  navigateTo(
                    1,
                    e,
                    reqBody.start_date,
                    reqBody.end_date,
                    reqBody.status,
                    reqBody.period,
                    reqBody.distributor_id
                  );
                }}
                total={dataList.count}
              />
            </div>
          </>
        ) : null}

        <Popover
          open={Boolean(anchorElDistributor)}
          anchorEl={anchorElDistributor}
          onClose={() => setAnchorElDistributor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-full relative max-h-[40vh] overflow-y-scroll">
            <div className="py-2 px-3 sticky top-0 bg-white z-50">
              <TextField
                fullWidth
                placeholder="Cari Distributor"
                value={searchDistributor}
                onChange={(e) => setSearchDistributor(e.target.value)}
              />
            </div>
            <div
              className={`py-2 px-3 whitespace-nowrap w-full flex gap-2 items-center cursor-pointer hover:text-gray-300`}
              onClick={() => {
                navigateTo(
                  1,
                  reqBody.limit,
                  reqBody.start_date,
                  reqBody.end_date,
                  reqBody.status,
                  reqBody.period,
                  dataDistributor &&
                    dataDistributor.data &&
                    reqBody.distributor_id.length <
                      dataDistributor.data.filter((item) => item.is_used).length
                    ? dataDistributor.data
                        .filter((item) => item.is_used)
                        .map((item) => item.id)
                    : []
                );
                setReqBody((prevVal) => ({
                  ...prevVal,
                  distributor_id:
                    dataDistributor &&
                    dataDistributor.data &&
                    reqBody.distributor_id.length <
                      dataDistributor.data.filter((item) => item.is_used).length
                      ? dataDistributor.data
                          .filter((item) => item.is_used)
                          .map((item) => item.id)
                      : [],
                }));
              }}
            >
              <input
                type="checkbox"
                checked={
                  dataDistributor &&
                  dataDistributor.data &&
                  reqBody.distributor_id.length ===
                    dataDistributor.data.filter((item) => item.is_used)
                      .length &&
                  reqBody.distributor_id.length > 0
                }
                className="cursor-pointer w-[16px] h-[16px]"
              />
              Semua
            </div>
            {dataDistributor &&
            !isFetchingDistributor &&
            dataDistributor.data &&
            dataDistributor.data.filter((item) => item.is_used).length > 0 ? (
              dataDistributor.data
                .filter((item) => item.is_used)
                .map((item, i) => (
                  <div
                    className={`py-2 px-3 whitespace-nowrap w-full border-t flex gap-2 items-center cursor-pointer hover:text-gray-300`}
                    key={i}
                    onClick={() => {
                      const index = reqBody.distributor_id.findIndex(
                        (dist) => dist === item.id
                      );
                      setBillingIdArr([]);

                      const newId =
                        index < 0
                          ? [...reqBody.distributor_id, item.id]
                          : [
                              ...reqBody.distributor_id.slice(0, index),
                              ...reqBody.distributor_id.slice(index + 1),
                            ];
                      navigateTo(
                        1,
                        reqBody.limit,
                        reqBody.start_date,
                        reqBody.end_date,
                        reqBody.status,
                        reqBody.period,
                        newId
                      );
                      setReqBody((prevVal) => ({
                        ...prevVal,
                        distributor_id: newId,
                      }));
                    }}
                  >
                    <input
                      type="checkbox"
                      className="cursor-pointer w-[16px] h-[16px]"
                      checked={reqBody.distributor_id.includes(item.id)}
                    />
                    {item.name}
                  </div>
                ))
            ) : (
              <div className="py-2 px-3 text-center">
                {isFetchingDistributor ? (
                  <CircularProgress size={20} />
                ) : (
                  "Tidak ada distributor"
                )}
              </div>
            )}
          </div>
        </Popover>

        <Popover
          open={Boolean(anchorElStatus)}
          anchorEl={anchorElStatus}
          onClose={() => setAnchorElStatus(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-full">
            <div
              className={`py-2 px-3 whitespace-nowrap w-full flex gap-2 items-center cursor-pointer hover:text-gray-300`}
              onClick={() => {
                navigateTo(
                  1,
                  reqBody.limit,
                  reqBody.start_date,
                  reqBody.end_date,
                  reqBody.status.length < STATUS.length
                    ? STATUS.map((stat) => stat.id)
                    : [],
                  reqBody.period,
                  reqBody.distributor_id
                );
                setReqBody((prevVal) => ({
                  ...prevVal,
                  status:
                    reqBody.status.length < STATUS.length
                      ? STATUS.map((stat) => stat.id)
                      : [],
                }));
              }}
            >
              <input
                type="checkbox"
                checked={
                  reqBody.status.length === STATUS.length &&
                  reqBody.status.length > 0
                }
                className="cursor-pointer w-[16px] h-[16px]"
              />
              Semua
            </div>
            {STATUS.map((item, i) => (
              <div
                className={`py-2 px-3 whitespace-nowrap w-full border-t flex gap-2 items-center cursor-pointer hover:text-gray-300`}
                key={i}
                onClick={() => {
                  const index = reqBody.status.findIndex(
                    (stat) => stat === item.id
                  );
                  setBillingIdArr([]);
                  const newStatus =
                    index < 0
                      ? [...reqBody.status, item.id]
                      : [
                          ...reqBody.status.slice(0, index),
                          ...reqBody.status.slice(index + 1),
                        ];
                  navigateTo(
                    1,
                    reqBody.limit,
                    reqBody.start_date,
                    reqBody.end_date,
                    newStatus,
                    reqBody.period,
                    reqBody.distributor_id
                  );
                  setReqBody((prevVal) => ({
                    ...prevVal,
                    status: newStatus,
                  }));
                }}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer w-[16px] h-[16px]"
                  checked={reqBody.status.includes(item.id)}
                />
                {item.value}
              </div>
            ))}
          </div>
        </Popover>

        <Popper
          placement="bottom"
          open={Boolean(anchorElMoreProduct)}
          anchorEl={anchorElMoreProduct}
          sx={{ maxWidth: "200px" }}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              // options: {
              //   element: arrowRef,
              // },
            },
          ]}
        >
          <div className="bg-white text-[10px] p-2 rounded-md border text-wrap whitespace-wrap overflow-hidden break-words">
            {moreProductArr.map((item, i) => `${i + 1}. ${item}`).join(", ")}
          </div>
        </Popper>

        <PopupRangeCalendar
          anchorElCal={anchorElCal}
          setAnchorElCal={setAnchorElCal}
          startDate={reqBody.start_date}
          endDate={reqBody.end_date}
          setStartDate={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
            navigateTo(
              reqBody.offset,
              reqBody.limit,
              e,
              reqBody.end_date,
              reqBody.status,
              reqBody.period,
              reqBody.distributor_id
            );
          }}
          setEndDate={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
            navigateTo(
              reqBody.offset,
              reqBody.limit,
              reqBody.start_date,
              e,
              reqBody.status,
              reqBody.period,
              reqBody.distributor_id
            );
          }}
          resetFunc={() => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: 1,
              start_date: INITIAL_REQ.start_date,
              end_date: INITIAL_REQ.end_date,
            }));
            navigateTo(
              reqBody.offset,
              reqBody.limit,
              INITIAL_REQ.start_date,
              INITIAL_REQ.end_date,
              reqBody.status,
              reqBody.period,
              reqBody.distributor_id
            );
          }}
        />

        <ExportBillingModal
          isWarehouse={false}
          openModal={openExport}
          setOpenModal={setOpenExport}
        />

        <ImportBillingModal
          isWarehouse={false}
          openModal={openImport}
          setOpenModal={setOpenImport}
        />
      </div>

      <div
        className={`${
          billingIdArr.length === 0 ? "bg-white" : "bg-blue-500 text-white"
        }  fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] shadow-lg shadow-slate-900/20 shadow-b-2 shadow-r-[3px] -shadow-spread-2 text-[12px] flex items-center z-50`}
      >
        <div className="flex items-center justify-between items-center w-full">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : dataList && !isLoading ? (
            <>
              {billingIdArr.length === 0 ? (
                <div>
                  <p>{dataList.date}</p>
                  <div className="flex gap-4">
                    <p>
                      Total Tagihan :{" "}
                      <span className="text-[16px] font-bold">
                        {toIDR(dataList.total_bill ? dataList.total_bill : 0)}
                      </span>
                    </p>
                    <p>
                      Total Dibayar :{" "}
                      <span className="text-[16px] font-bold">
                        {toIDR(
                          dataList.total_payment ? dataList.total_payment : 0
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between w-full items-center w-full">
                  <div className="w-full flex items-center gap-4">
                    <div className="text-white">
                      <p className="text-[14px]">Total</p>
                      <p className="text-[18px] font-bold">
                        {toIDR(getTotalBilling())}
                      </p>
                    </div>
                    <div className="text-white">
                      <p className="text-[14px]">Total Lunas</p>
                      <p className="text-[18px] font-bold">
                        {toIDR(getTotalPaid())}
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    disabled={billingIdArr.length < 1 || isAllPaid()}
                    onClick={() => {
                      setOpenPay(true);
                    }}
                    className="rounded-xl text-[14px] disabled:bg-[#687B8E] text-white py-[10px] px-[42px] bg-[#FF8D40] hover:bg-orange-500"
                  >
                    {isAllPaid() ? "Semua transaksi sudah lunas" : "Bayar"}
                  </button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>

      <Dialog
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold w-full"
            onClick={() => {
              setOpenCalendar(false);
              setPeriodDummy(10);
              setReqBody((prevVal) => ({
                ...prevVal,
                start_date: startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : "",
                end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
              }));
              navigateTo(
                reqBody.offset,
                reqBody.limit,
                startDate ? moment(startDate).format("YYYY-MM-DD") : "",
                endDate ? moment(endDate).format("YYYY-MM-DD") : "",
                reqBody.status,
                reqBody.period,
                reqBody.distributor_id
              );
            }}
          >
            Konfirmasi
          </button>
        </DialogContent>
      </Dialog>

      <ModalPaymentBillingList
        open={openPay}
        setOpen={setOpenPay}
        billing={
          dataList && !isLoading && dataList.data
            ? dataList.data.filter(
                (item) =>
                  billingIdArr.includes(item.billing_id) && item.status !== 3
              )
            : []
        }
        refetch={() => {
          setReqBody(INITIAL_REQ);
          mutateList(reqBody);
          setBillingIdArr([]);
          navigate(`/manual-billing`);
        }}
      />
    </>
  );
};

export default BillingManualList;
